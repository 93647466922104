

.get-started-section-body {
  font-family: 'Nunito', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
}

.get-started-section {
  width: 100%;
  text-align: center;
  padding: 50px 20px;
  background: linear-gradient(135deg, #7B5F9E, #9D7EC1);
  color: #FFF;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}

.get-started-section h1 {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 20px;
}

.button-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.btn {
  padding: 12px 30px;
  font-size: 1rem;
  font-weight: 700;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.btn-learn {
  background-color: #FFF;
  color: #7B5F9E;
  box-shadow: 0 4px 12px rgba(255, 255, 255, 0.3);
}

.btn-get-started {
  background-color: #3B286D;
  color: #FFF;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
}

.btn-learn:hover {
  background-color: #DDD6E1;
  color: #3B286D;
}

.btn-get-started:hover {
  background-color: #523787;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.35);
}

/* Responsive Design */
@media (max-width: 600px) {
  .get-started-section h1 {
    font-size: 1.8rem;
  }

  .btn {
    padding: 10px 20px;
    font-size: 0.9rem;
  }
}
