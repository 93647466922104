
/* Testimonial Container */
.testimonial-container {
  padding: 100px 20px;
  background: linear-gradient(135deg, #4f46e5, #6b21a8);
  color: white;
  text-align: center;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
}

.testimonial-title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 30px;
  letter-spacing: 1px;
}

/* Testimonial Slider */
.testimonial-slider {
  max-width: 900px;
  margin: 0 auto;
  position: relative;
}

.testimonial-card {
  background: rgba(255, 255, 255, 0.8);
  border-radius: 12px;
  padding: 30px;
  text-align: center;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transform: scale(0.98);
  transition: transform 0.3s ease-in-out;
}

.testimonial-card:hover {
  transform: scale(1);
}

.testimonial-quote {
  font-size: 1.25rem;
  font-style: italic;
  color: #333;
  margin-bottom: 15px;
  transition: opacity 0.3s ease-in-out;
}

.testimonial-author {
  font-size: 1.2rem;
  font-weight: bold;
  color: #4f46e5;
  margin-bottom: 10px;
}

.testimonial-stars {
  color: #f59e0b;
  font-size: 1.4rem;
}

/* Slider Controls */
.slider-controls {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.slider-button {
  background-color: #4f46e5;
    border: none;
    border-radius: 20%;
    color: #fff;
    cursor: pointer;
    font-size: 1rem;
    padding: 20px;
    transition: background-color .3s, transform .2s;
}

.slider-button:hover {
  background-color: #3b3fa5;
  transform: scale(1.1);
}

.slider-button:focus {
  outline: none;
}

/* Responsive Design */
@media (max-width: 768px) {
  .testimonial-title {
    font-size: 1.8rem;
  }

  .testimonial-card {
    padding: 20px;
  }

  .testimonial-quote {
    font-size: 1rem;
  }

  .slider-button {
    font-size: 1.5rem;
    padding: 12px;
  }
}
