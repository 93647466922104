
/* Insurance Section */
.insurance-section {
  background: linear-gradient(rgba(104, 119, 168, 0.87) 36.7112%, #2c649c 100%);
  padding: 50px 0;
}

.insurance-section img {
  height: 40px;
}

.info-section-body {
  background-color: #3a5a98;
  color: #ffffff;
  padding: 3rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  gap: 2rem;
  margin: 2rem auto;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.2);
}

.info-section {
  color: #ffffff;
  padding: 3rem;
  border-radius: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  gap: 2rem;
  margin: 2rem auto;
  max-width: 1200px;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.2);
}

.info-text {
  flex: 1;
  min-width: 280px;
}

.info-text h1 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.info-text p {
  font-size: 1rem;
  margin-bottom: 1.5rem;
}

.btn-primary {
  background-color: #ffffff;
  color: #3a5a98;
  border: none;
  font-weight: bold;
  padding: 0.8rem 1.5rem;
  border-radius: 25px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.btn-primary:hover {
  background-color: #ffcc5c;
  color: #ffffff;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {

  .insurance-section {
    flex-direction: column;
  }
}