


.btn-assessment {
  background-color: #5F7A71;
  /* Green button */
  color: white;
  font-weight: bold;
  border-radius: 25px;
  padding: 10px 20px;
}

.banner {
  display: flex;
  align-items: stretch;
  /* height: 100vh; */
  background: linear-gradient(180deg, #b3fcec, #f3f3ff);
  /* Full height of the viewport */
}

.content-section {
  padding: 50px;
  background: linear-gradient(180deg, #b3fcec, #f3f3ff);
}

/* .image-section {
  height: 100vh;
  overflow: hidden;
} */
.image-section {
  height: auto;
  overflow: hidden;
}

/* .yoga-img {
  height: 100%;
  width: 100%;
  object-fit: cover; */
  /* Ensures the image fills the space without distortion */
/* } */
.yoga-img {
  height: auto;
  width: 100%;
  object-fit: cover;
}

.banner h1 {
  font-weight: 500;
  margin-bottom: 20px;
  font-size: 2.8rem;
  color: #003366;
  margin-bottom: 20px;
}

.banner p {
  color: #666666;
  margin-bottom: 30px;
  font-size: 16px;
}

.btn-primary {
  background-color: #06147e;
  border: none;
  padding: 15px 30px;
  font-size: 16px;
  font-weight: 700;
  border-radius: 30px;
}

.btn-primary:hover {
  background-color: #2233aa;
}



@keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-15px);
  }

  60% {
    transform: translateY(-10px);
  }
}



/* Media Query for Smaller Screens */
@media (max-width: 768px) {
  .banner h1 {
    font-size: 2rem;
    /* Adjust font size on smaller screens */
  }

  
}

