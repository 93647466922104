/* About Us Section */
.about-section {

    padding: 60px 20px;
    color: #333;
  }
  
  .section-header {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .section-header h3 {
    font-size: 2.5rem;
    color: #333;
    margin: 0;
  }
  
  .about-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  @media(min-width: 768px) {
    .about-container {
      flex-direction: row;
    }
  }
  
  .about-image-wrapper {
    position: relative;
    width: 100%;
    max-width: 400px;
    height: 100%;
  }
  
  .about-image {
    width: 100%;
    border-radius: 15px;
    object-fit: cover;
    transition: transform 0.3s ease;
  }
  
  .about-image-wrapper:hover .about-image {
    transform: scale(1.05);
  }
  
  .about-overlay {
    position: absolute;
    bottom: 10px;
    left: 10px;
    background: rgba(124, 135, 178, 0.9);
    padding: 15px;
    border-radius: 8px;
    color: #fff;
    text-align: center;
  }
  
  .about-content {
    flex: 1;
    padding: 20px;
    background: #7c87b2;
    border-radius: 15px;
    color: #fff;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .about-title {
    font-size: 1.8rem;
    margin-bottom: 15px;
  }
  
  .about-mission-vision {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 30px;
  }
  
  @media(min-width: 768px) {
    .about-mission-vision {
      flex-direction: row;
      gap: 30px;
    }
  }
  
  .mission, .vision {
    flex: 1;
    padding: 20px;
    background-color: #d6cdac;
    border-radius: 10px;
    text-align: center;
    color: #333;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }
  
  .mission h2, .vision h2 {
    font-size: 1.6rem;
    margin-bottom: 10px;
  }
  