
.section-heading {
  font-weight: 700;
  font-size: 2rem;
  color: #004085;
}

.section-subheading {
  font-size: 1.1rem;
  color: #6c757d;
}

.pricing-card {
  border-radius: 20px;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s ease;
}

.pricing-card:hover {
  transform: scale(1.03);
}

.card-header {
  font-weight: bold;
  font-size: 1.2rem;
  padding: 1rem;
  color: #ffffff;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  text-align: center;
}

.individual {
  background: linear-gradient(135deg, #a1c4fd, #c2e9fb);
}

.plus {
  background: linear-gradient(135deg, #b0dab9, #c2e9fb);
}

.premium {
  background: linear-gradient(135deg, #ffb2e6, #ffdfba);
}

.premium-crisis {
  background: linear-gradient(135deg, #89f7fe, #66a6ff);
}

.card-body {
  padding: 2rem;
  text-align: center;
}

.price {
  font-size: 1.8rem;
  font-weight: bold;
  color: #004085;
  margin-top: 0.5rem;
}

.btn-get-started {
  background-color: #004085;
  color: #fff;
  padding: 0.7rem 2rem;
  border-radius: 8px;
  font-weight: 600;
  margin-top: 1.5rem;
  transition: background-color 0.3s ease;
}

.btn-get-started:hover {
  background-color: #002752;
}

/* Mobile Responsive Design */
@media (max-width: 767.98px) {
  .pricing-card {
    margin-bottom: 1rem;
  }

  .price {
    font-size: 1.5rem;
  }

  .btn-get-started {
    padding: 0.5rem 1.5rem;
  }
}