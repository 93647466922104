/* Chatbot.css */

.chatbot-container {
  position: fixed;
  bottom: 0;
  z-index: 1000;
  right: 20px;
  max-width: 300px;
  width: 100%;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 1, 0, 0.15);
  display: flex;
  flex-direction: column;
  transition: max-height 0.3s ease;
  overflow-y: auto;
  max-height: 60vh;
  padding-bottom: env(safe-area-inset-bottom);
}

.chatbot-header {
  background-color: #007bff;
  color: #fff;
  padding: 10px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chatbot-messages {
  padding: 10px;
  overflow-y: auto;
  max-height: calc(100vh - 160px);
  min-height: 330px;
  flex-grow: 1;
  padding-bottom: 10px;
}

.chatbot-input {
  display: flex;
  padding: 10px;
  border-top: 1px solid #ccc;
  position: sticky;
  bottom: 0;
  background-color: #fff;
  z-index: 10;
}

.chatbot-input input {
  flex-grow: 1;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.chatbot-input button {
  margin-left: 8px;
  padding: 8px 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
}

.chatbot-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #007bff;
  color: #fff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  border: none;
  cursor: pointer;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
}

/* Style specifically for user messages */
.user {
  background-color: #e1f5fe;
  text-align: right;
}

/* Style specifically for chatbot messages */
.Clarity {
  background-color: #e8f5e9;
  text-align: left;
}

/* Style for the "book appointment" link */
.chatbot-link {
  display: inline-block;
  margin-top: 5px;
  padding: 8px 12px;
  background-color: #4CAF50;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.chatbot-link:hover {
  background-color: #45a049; 
}

/* Style for link container for spacing */
.chatbot-messages p div {
  margin-top: 10px;
}

/* Full-screen view for mobile */
@media (max-width: 768px) {
  .chatbot-container {
    right: 0;
    border-radius: 0;
    height: 100vh;
    width: 100%;
  }
}
