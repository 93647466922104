

/*phone section*/
.phonebody {
  background: linear-gradient(rgba(191, 145, 255, 0.11) 0%, rgb(145, 118, 181) 82.422%);
  color: #333;
}

.how-it-works {
  padding: 60px 0;
  text-align: center;
}

.how-it-works h2 {
  font-weight: 700;
  color: #003366;
  margin-bottom: 20px;
}

.how-it-works p {
  color: #000000;
  margin-bottom: 50px;
}

.feature-item {
  text-align: left;
  margin-bottom: 40px;
  display: flex;
  align-items: flex-start;
}

.feature-item:hover {
  border-radius: 12px;
  background: #fff;
  color: #000;
  padding: 5px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.feature-item img {
  height: 40px;
  margin-right: 15px;
}

.feature-item h6 {
  font-weight: 700;
  font-size: 16px;
  color: #003366;
  margin-bottom: 10px;
}

.feature-item p {
  font-size: 14px;
  color: #000000;
}

.phone-section {
  max-width: 400px;
  margin: 0 auto;
  position: relative;
  margin-bottom: 40px;
}

.phone-mockup {
  max-width: 100%;
}

.phone-mockup:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.phone-section:hover .phone-mockup {
  transform: scale(1.05);
}

.cta {
  font-weight: 700;
  color: #003366;
  margin-top: 40px;
}

.cta a {
  color: #0a135a;
  text-decoration: none;
  border-bottom: 2px solid #3344cc;
  padding-bottom: 3px;
}

.cta a:hover {
  color: #2233aa;
  border-color: #2233aa;
  text-decoration: underline;
}

@media (max-width: 768px) {
  .phone-section {
    margin-top: 20px;
  }

  .cta {
    margin-top: 30px;
  }
}
